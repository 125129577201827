/* Contacto.css */

.contacto-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 70vh;
    padding: 50px;
    width: 100vw;
  }
  
  .contacto-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin-bottom: 40px; /* Aumentamos el margen inferior */
    margin-left: 50px;
  }
  
  .contacto-logo img {
    width: 200px;
    border-radius: 50%;
  }
  
  .contacto-info {
    margin-top: 20px;
  }
  
  .contacto-redes-sociales {
    margin-top: 20px;
    width: 100%;
  }
  
  .contacto-redes-sociales ul {
    list-style: none;
    padding: 0;
    display: flex; /* Cambiamos a flex para separar los elementos */
    justify-content: flex-start; /* Distribuye los elementos equitativamente */
  }
  
  .contacto-redes-sociales ul li {
    margin-right: 20px;
    background-color: #01703d;
    padding: 10px;
    border-radius: 25%;
    text-align: center;
    color: white;
  }
  
  .contacto-sucursales {
    width: 100vw;
    padding-left: 40vw;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    text-align: left;
  }
  
  .contacto-sucursales-title{
    background-color: #01703d;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    border-radius: 10px; 
    color: white;
    font-size: 2vw;
    font-weight: bolder;
  }
  
  .contacto-sucursales div {
    flex: 1;
    width: fit-content;
  }


  .contacto-sucursales h5 {
    margin-bottom: 5px;
    width: fit-content;
    font-size: 2vw;
    color: #01703d;
  }

  .contacto-sucursales p {
    font-size: 1.2vw;
  }


  .meli-logo-contact{
    scale: 1.5;
  }

  .icon-contact{
    margin-right: 10px;
    
  }