footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo img {
    width: 100px; /* ajusta el tamaño según sea necesario */
    border-radius: 50%;
    margin-right: 100px;
  }
  
  .sucursales, .redes-sociales {
    flex: 1;
    width: fit-content;
  }
  
  .redes-sociales ul {
    list-style: none;
    padding: 0;
    
  }
  
  .redes-sociales ul li {
    margin-bottom: 10px;
    width: fit-content;
  }
  
  .redes-sociales ul li a {
    color: #fff;
    text-decoration: none;
    width: fit-content;
  }
  
  .redes-sociales ul li a:hover {
    color: #ffcc00; /* color de resaltado al pasar el ratón */
  }
  
  .sucursal {
    display: flex;
    margin-bottom: 20px;
    text-align: right;
  }
  
  .sucursal div {
    flex: 1;
    width: fit-content;
  }
  
  .sucursal h5 {
    margin-bottom: 5px;
  }
  
  .sucursal p {
    margin: 0;
  }
  
  .sucursales{
    text-align: right;
    padding-right: 20px;
  }

  .socials-footer ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-left: 0;

  }

  .socials-footer ul li{
    padding-right: 15px;
  }