nav {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .logo img {
    width: 100px; /* ajusta el tamaño según sea necesario */
    border-radius: 50%;
    margin-left: 50px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
  }
  
  .nav-links li {
    margin-right: 20px;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #fff;
  }
  
  .nav-links li a:hover {
    color: #01703d; /* color de resaltado al pasar el ratón */
  }
  