.home-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 70vh; /* Esto asegura que el contenido ocupe al menos toda la altura de la ventana */
  padding-top: 80px; /* Ajusta el espacio superior para compensar el NavBar */
  padding-bottom: 80px; /* Ajusta el espacio inferior para compensar el Footer */

  }
  
  .logo-home img {
    height: 60vh; /* La imagen ocupa el 40% de la altura de la ventana */
    max-width: 100%; /* La imagen se ajusta al ancho máximo del contenedor */
    padding-inline: 50px;
    border-radius: 50%;
  }
  
  .contact-info {
    text-align: left;
    max-width: 800px; /* Ajusta el ancho máximo del texto según sea necesario */
    margin-bottom: 20px; /* Espacio entre el texto y la imagen */
    padding-inline: 50px;
  }

  .contact-info h1{
    font-size: 3vw;
    color: #01703d;
  }

  .contact-info p{
    text-decoration: underline;
    font-size: 1.2vw;
    align-items: center;
  }